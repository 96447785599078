<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "@/components/widgets/stat";


/**
 * Saas Dashboard Component
 */
export default {
  page: {
    title: "Settings",
  },
  components: {
    Layout,
    PageHeader,
    Stat,
  },
  data() {
    return {
      title: "Settings",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Settings",
          active: true,
        },
      ],
      statData: [
        {
          icon: "bx bx-accessibility",
        //   title: $t('menuitems.setting.list.state-services'),
        value: "Services d'Etat",
        link: "/setting/state-services"
        },
        {
          icon: "bx bx-support",
        //   title: $t('menuitems.setting.list.treatment-center'),
        value: "Centres de traitement",
        link: "/setting/treatment-center"
        },
        {
          icon: "bx bx-sitemap",
          value: "Agents d'intervention",
          link: "/setting/intervention-officers"
        //   title: $t('menuitems.setting.list.intervention-officers'),
        //   value: "$16.2",
        },
        {
          icon: "bx bx-user",
          value: "Agents de centre de traitement",
          link: "/setting/treatment-officers"
        },
        {
          icon: "bx bx-bell-plus",
          value: "Paramètres d'alerte",
        },
      ],
      chat: {
        message: "",
      },
    };
  },
 
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-6">
            <Stat :icon="stat.icon" :value="stat.value" :link="stat.link" />
          </div>
    </div>
    
  </Layout>
</template>